<template>
  <div>
    <b-card
      title="Liste des adresses de paiment"
      title-tag="h5"
    >
      <b-row>
        <b-col sm="4">
          <b-form-select
            v-model="tarifId"
            :options="tarifsOptions"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col sm="12">
          <b-table
            :items="items"
            :fields="fields"
            :busy="loaded"
            responsive
            show-empty
            empty-text="Aucune adresse"
          >
            <template #table-busy>
              <div class="d-flex justify-content-center mb-3">
                <b-spinner
                  variant="primary"
                  label="Loading..."
                />
              </div>
            </template>
            <template v-slot:cell(state)="data">
              {{ Number(data.item.state) === 1 ? 'Actif' : 'Inactif' }}
            </template>
            <template v-slot:cell(action)="data">
              <div class="cursor-pointer">
                <b-dropdown
                  variant="link"
                  dropleft
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <b-icon
                      class="ml-1 text-info"
                      icon="three-dots-vertical"
                      font-scale="1.2"
                    />
                  </template>

                  <b-dropdown-item @click="openEditModal(data.item)">
                    <b-icon
                      class="ml-1 text-warning"
                      icon="pencil"
                      font-scale="1.2"
                    />
                    <span class="ml-1">Modifier</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="openDeleteModal(data.item)">
                    <b-icon
                      class="ml-1 text-danger"
                      icon="trash"
                      font-scale="1.2"
                    />
                    <span class="ml-1">Supprimer</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>

            </template>
            <template>
              <div class="d-flex justify-content-center mb-3">
                <b-spinner
                  variant="primary"
                  label="Loading..."
                />
              </div>
            </template>
          </b-table>
          <div class="mt-2">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              align="right"
            />
          </div>
        </b-col>
      </b-row>

    </b-card>
    <b-modal
      v-if="showEditModal"
      v-model="showEditModal"
      :no-close-on-backdrop="updateProcess"
      hide-footer
      centered
      title="Modification"
      @close="closeEditModal"
    >
      <div :class="animation">
        <b-col sm="12">
          <b-form-group label="Dénomination">
            <b-form-input
              v-model="$v.currentAddress.assocName.$model"
              :formatter="formatter"
              type="text"
              :class="{
                'is-invalid': $v.currentAddress.assocName.$error,
              }"
            />
            <div
              v-if="$v.currentAddress.assocName.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.currentAddress.assocName.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group label="Solde disponible">
            <b-form-input
              v-model.number="$v.currentAddress.value.$model"
              type="number"
              :class="{
                'is-invalid': $v.currentAddress.value.$error,
              }"
            />
            <div
              v-if="$v.currentAddress.value.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.currentAddress.value.required">{{
                $t("errors.required")
              }}</span>
              <span v-if="!$v.currentAddress.value.numeric">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group label="Adresse de paiment">
            <b-form-input
              v-model="$v.currentAddress.address.$model"
              type="text"
              :class="{
                'is-invalid': $v.currentAddress.address.$error,
              }"
            />
            <div
              v-if="$v.currentAddress.address.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.currentAddress.address.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group label="Appliqué ?">
            <b-form-checkbox
              v-model="$v.currentAddress.state.$model"
              :value="true"
              :unchecked-value="false"
            >
              <span>{{ currentAddress.state ? 'Oui' : 'Non' }}</span>
            </b-form-checkbox>
            <div
              v-if="$v.currentAddress.state.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.currentAddress.state.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
        >
          <div class="text-center">
            <b-spinner
              v-if="updateProcess"
              variant="primary"
            />
          </div>
          <div class="text-right mt-2">
            <b-button
              variant="outline-primary"
              :disabled="updateProcess"
              @click="tryToUpdate"
            >
              <span>Modifier</span>
            </b-button>
          </div>
        </b-col>
      </div>
    </b-modal>
    <b-modal
      v-if="showDeleteModal"
      v-model="showDeleteModal"
      :no-close-on-backdrop="deleteProcess"
      hide-footer
      centered
      title="Suppression"
      @close="closeDeleteModal"
    >
      <div :class="animation">
        <div>
          Voulez-vous vraiment supprimer l'adresse de paiment {{ currentAddress.address }} du systeme ?
        </div>
        <b-col
          sm="12"
        >
          <div class="text-center">
            <b-spinner
              v-if="deleteProcess"
              variant="primary"
            />
          </div>
          <div class="text-right mt-2">
            <b-button
              variant="outline-danger"
              :disabled="deleteProcess"
              @click="tryToDelete"
            >
              <span>Supprimer</span>
            </b-button>
          </div>
        </b-col>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, numeric } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      animation: null,
      currentPage: 1,
      perPage: 5,
      items: [],
      fields: [
        { key: 'assoc_name', label: 'Nom' },
        { key: 'address', label: 'Adresse' },
        { key: 'value', label: 'Solde disponible' },
        { key: 'state', label: 'Etat' },
        { key: 'action', label: 'Action', class: 'text-center' },
      ],
      loaded: false,
      totalItems: 0,
      tarifId: null,
      allCurrencies: [],
      showEditModal: false,
      showDeleteModal: false,
      currentAddress: {
        id: null,
        assocName: null,
        state: false,
        value: null,
        address: null,
      },
    }
  },
  validations: {
    currentAddress: {
      assocName: {
        required,
      },
      state: {
        required,
      },
      value: {
        required,
        numeric,
      },
      address: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      updateSuccess: 'updateAddressSuccess',
      updateError: 'updateAddressError',
      updateProcess: 'updateAddressProcess',

      deleteSuccess: 'deleteAddressSuccess',
      deleteError: 'deleteAddressError',
      deleteProcess: 'deleteAddressProcess',
    }),
    tarifsOptions() {
      const tarifs = this.allCurrencies
      const data = [
        { value: null, text: 'Chosir', disabled: true },
      ]
      if (tarifs.length > 0) {
        // eslint-disable-next-line array-callback-return
        tarifs.map(item => {
          let current
          if (!item.is_auto && !item.is_crypto && item.dev_code !== 'VISA') {
            current = {
              value: item.id,
              text: item.libelle,
            }
            data.push(current)
          }
        })
      }
      return data
    },
  },
  watch: {
    tarifId(val) {
      if (val) {
        this.items = []
        this.getAllAddress()
      }
    },
    $route: {
      immediate: true,
      handler() {
        this.getAllCurrencies()
      },
    },
    updateSuccess(val) {
      if (val) {
        this.closeEditModal()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('address.update-title'),
            text: this.$t('address.update-success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.getAllAddress()
      }
    },
    updateError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('address.update-title'),
            text: this.$t('address.update-error'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    deleteSuccess(val) {
      if (val) {
        this.closeDeleteModal()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('address.delete-title'),
            text: this.$t('address.delete-success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.getAllCurrencies()
      }
    },
    deleteError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('address.delete-title'),
            text: this.$t('address.delete-error'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['updateAddress', 'deleteAddress']),
    formatter(value) {
      return value.toUpperCase()
    },
    openEditModal(item) {
      this.currentAddress = {
        assocName: item.assoc_name,
        id: item.id,
        state: Number(item.state) === 1,
        value: item.value,
        address: item.address,
      }
      this.showEditModal = true
    },
    closeEditModal() {
      this.showEditModal = false
      this.currentAddress = null
    },
    openDeleteModal(item) {
      this.currentAddress = {
        assocName: item.assoc_name,
        id: item.id,
        state: Number(item.state) === 1,
        value: item.value,
        address: item.address,
      }
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.showDeleteModal = false
      this.currentAddress = null
    },
    async getAllAddress() {
      this.loaded = true
      this.items = await this.$http
        .get(`/tarif-receipt-address/find-by-tarif/${this.tarifId}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          const { data } = res.data
          this.totalItems = data.length
          this.loaded = false
          return data
        })
        .catch(() => {
          this.loaded = false
        })
    },
    async getAllCurrencies() {
      this.allCurrencies = await
      this.$http
        .get('/currencies/all', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => res.data.data)
        .catch(() => [])
    },
    tryToUpdate() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.animation = 'animate-wrong'
        setTimeout(() => {
          this.animation = null
        }, 1000)
      }
      this.updateAddress({
        id: this.currentAddress.id,
        assocName: this.currentAddress.assocName,
        tarifId: this.tarifId,
        state: this.currentAddress.state,
        value: this.currentAddress.value,
        address: this.currentAddress.address,
      })
    },
    tryToDelete() {
      this.deleteAddress({
        id: this.currentAddress.id,
      })
    },
  },
}
</script>

<style>
.animate-wrong {
  animation: wrong 0.4s ease-in-out;
}

@keyframes wrong {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(40px);
  }
  40% {
    transform: translateX(20px);
  }
  60% {
    transform: translateX(40px);
  }
  80% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
